<template>
  <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateRangeText"
          placeholder="Filter History By Dates"
          label="Filter History By Dates"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          dense
          outlined
          filled
          clearable
          @click:clear="clearDates()"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="dates"
        range
        @input="submitRange()"
    >
    </v-date-picker>
  </v-menu>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DateTimePicker',
  data: () => ({
    dateMenu: false,
    dates: []
  }),

  created () {
    this.dates = this.filterDates.length ? [...this.filterDates]
        : []
  },
  computed: {
    ...mapGetters('Site', ['getFilterDates']),

    dateRangeText () {
      return this.dates.length ? this.dates.join(' ~ ') : null
    },

    filterDates(){
      return this.getFilterDates
    }
  },

  methods: {
    ...mapActions('Site', ['updateFilterDates']),
    submitRange(){
      if (this.dates.length === 2){
        this.dateMenu = false;
        this.updateFilterDates(this.dates)
      }
    },

    clearDates(){
      this.dates = []
      this.updateFilterDates([])
    }
  }
}
</script>

<style scoped>

</style>
